import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-form-capex-type-pose',
  templateUrl: './form-capex-type-pose.component.html',
  styleUrls: ['./form-capex-type-pose.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass, NgbAccordionModule],
})
export class FormCapexTypePoseComponent implements OnInit {
  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()
  capexTypePoseForm: FormGroup

  _shouldDisplaySectionToiture: boolean
  @Input()
  set shouldDisplaySectionToiture(bToiture: boolean) {
    this._shouldDisplaySectionToiture = bToiture
    if (!bToiture) {
      this.capexTypePoseForm.get('mlGardeCorpsProvisoire').reset()
      this.capexTypePoseForm.get('mlLigneDeVie').reset()
      this.capexTypePoseForm.get('nbEchelleCrinoline').reset()
    }
  }

  _shouldDisplaySectionSol: boolean
  @Input()
  set shouldDisplaySectionSol(bSol: boolean) {
    this._shouldDisplaySectionSol = bSol
    if (!bSol) {
      this.capexTypePoseForm.get('mlClotureSol').reset()
      this.capexTypePoseForm.get('nbPortailsSol').reset()
    }
  }

  _shouldDisplaySectionOmbriere: boolean
  @Input()
  set shouldDisplaySectionOmbriere(bOmbriere: boolean) {
    this._shouldDisplaySectionOmbriere = bOmbriere
    if (!bOmbriere) {
      this.capexTypePoseForm.get('deposeCandelabre').reset()
      this.capexTypePoseForm.get('poseCandelabre').reset()
      this.capexTypePoseForm.get('eclairageSousOmbriere').setValue(false)
      this.capexTypePoseForm.get('descenteEpAlu').setValue(false)
      this.capexTypePoseForm.get('nbAbattageArbres').reset()
    }
  }

  constructor(private formBuilder: FormBuilder) {
    this.capexTypePoseForm = this.formBuilder.group({
      deposeCandelabre: [null],
      poseCandelabre: [null],
      eclairageSousOmbriere: [false],
      descenteEpAlu: [false],
      nbAbattageArbres: [null],
      mlClotureSol: [null],
      nbPortailsSol: [null],
      mlGardeCorpsProvisoire: [null],
      mlLigneDeVie: [null],
      nbEchelleCrinoline: [null]
    })
  }

  ngOnInit(): void {
    this.formReady.emit(this.capexTypePoseForm)
  }
}
