<div class="container">
  <div class="sunleon-container row">
    <div class="sunleon-presentation col-12">
      <div class="sunleon-form">
        <form [formGroup]="sunleonForm" (ngSubmit)="saveSimulation()" autocomplete="off" id="formABC"
              [ngClass]="{'disabled-form': modalService.hasOpenModals()}">
          <h1>Saisie des données de simulation</h1>
          <div class="mb-2">
            <label for="nomClient" class="form-label">Client  <span class="required">*</span></label>
            <input type="text" class="form-control" id="nomClient" formControlName="nomClient" placeholder="Ex : Marquere Cie" tabindex="1"
                   [ngClass]="sunleonForm.get('nomClient').invalid && (sunleonForm.get('nomClient').dirty || sunleonForm.get('nomClient').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="sunleonForm.get('nomClient').errors?.['required']">Le client est obligatoire</div>
            </div>
          </div>
          <div class="mb-2">
            <label for="nomProjet" class="form-label">Nom du projet  <span class="required">*</span></label>
            <input type="text" class="form-control" id="nomProjet" formControlName="nomProjet" placeholder="Ex : 345 - Marquere à Chaponost" tabindex="2"
                   [ngClass]="sunleonForm.get('nomProjet').invalid && (sunleonForm.get('nomProjet').dirty || sunleonForm.get('nomProjet').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="sunleonForm.get('nomProjet').errors?.['required']">Le projet est obligatoire</div>
            </div>
          </div>
          <div class="mb-2">
            <label for="numMonday" class="form-label">Id Monday.com</label>
            <input type="text" class="form-control" id="numMonday" formControlName="numMonday" placeholder="Ex : 201" tabindex="3">
          </div>
          <h2>Coordonnées GPS</h2>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <label for="latitude" class="form-label">Latitude <span class="required">*</span></label>
                <input type="number" class="form-control" id="latitude" formControlName="latitude" placeholder="Ex : 48.07997" tabindex="4"
                       [ngClass]="sunleonForm.get('latitude').invalid && (sunleonForm.get('latitude').dirty || sunleonForm.get('latitude').touched) ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                  <div *ngIf="sunleonForm.get('latitude').errors?.['required']">La latitude est obligatoire</div>
                </div>
              </div>
            </div>
            <div class="col-6">
                <div class="mb-2">
                  <label for="longitude" class="form-label">Longitude <span class="required">*</span></label>
                  <input type="number" class="form-control" id="longitude" formControlName="longitude" placeholder="Ex : 0.25298" tabindex="5"
                         [ngClass]="sunleonForm.get('longitude').invalid && (sunleonForm.get('longitude').dirty || sunleonForm.get('longitude').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('longitude').errors?.['required']">La longitude est obligatoire</div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <label for="departement" class="form-label">Département <span class="required">*</span></label>
                <input type="text" class="form-control" id="departement" formControlName="departement" placeholder="Ex : 01" tabindex="6"
                       [ngClass]="sunleonForm.get('departement').invalid && (sunleonForm.get('departement').dirty || sunleonForm.get('departement').touched) ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                  <div *ngIf="sunleonForm.get('departement').errors?.['required']">Le département est obligatoire</div>
                  <div *ngIf="sunleonForm.get('departement').errors?.['pattern']">Le département doit être de deux chiffres compris entre 01 et 95 ou 2A ou 2B, excluant 20.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="sunleon-zone">
            <h2>Zone n°1</h2>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label" for="typePoseZone1">Type de pose <span class="required">*</span></label>
                  <select class="form-select" id="typePoseZone1" formControlName="typePoseZone1" tabindex="7"
                          [ngClass]="sunleonForm.get('typePoseZone1').invalid && (sunleonForm.get('typePoseZone1').dirty || sunleonForm.get('typePoseZone1').touched) ? 'is-invalid' : ''">
                    <option value=""></option>
                    <option *ngFor="let typePose of typePoses" [ngValue]="typePose.code"
                            [selected]="sunleonForm.get('typePoseZone1').value && typePose.code === sunleonForm.get('typePoseZone1').value">
                      {{ typePose.label }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('typePoseZone1').errors?.['required']">Le type de pose est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="surfaceZone1" class="form-label">Surface <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="surfaceZone1" formControlName="surfaceZone1" placeholder="Ex : 45.32" tabindex="8"
                             [ngClass]="sunleonForm.get('surfaceZone1').invalid && (sunleonForm.get('surfaceZone1').dirty || sunleonForm.get('surfaceZone1').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('surfaceZone1').errors?.['required']">La surface est obligatoire</div>
                        <div *ngIf="sunleonForm.get('surfaceZone1').errors?.['pattern']">La surface n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">m²</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="azimutZone1" class="form-label">Azimut (compris entre -180 et 180, SUD = 0) <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="azimutZone1" formControlName="azimutZone1" placeholder="Ex : 100" tabindex="9"
                             [ngClass]="sunleonForm.get('azimutZone1').invalid && (sunleonForm.get('azimutZone1').dirty || sunleonForm.get('azimutZone1').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('azimutZone1').errors?.['required']">L'azimut est obligatoire</div>
                        <div *ngIf="sunleonForm.get('azimutZone1').errors?.['pattern']">L'azimut n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">°</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="sunleonForm.get('typePoseZone1').value === 'TOITURE'" >
            <h2>Toiture</h2>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label" for="typeToitureZone1">Type toiture <span class="required">*</span></label>
                  <select class="form-select" id="typeToitureZone1" formControlName="typeToitureZone1" tabindex="10"
                          [ngClass]="sunleonForm.get('typeToitureZone1').invalid && (sunleonForm.get('typeToitureZone1').dirty || sunleonForm.get('typeToitureZone1').touched) ? 'is-invalid' : ''">
                    <option value=""></option>
                    <option *ngFor="let typeToitureZone1 of typeToitures" [ngValue]="typeToitureZone1.code"
                            [selected]="sunleonForm.get('typeToitureZone1').value && typeToitureZone1.code === sunleonForm.get('typeToitureZone1').value">
                      {{ typeToitureZone1.label }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('typeToitureZone1').errors?.['required']">Le type de toiture est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group form-check">
                  <span class="spanCheckbox">PV Ready <span class="required">*</span></span>
                  <input class="form-check-input" type="checkbox" formControlName="pvReadyZone1" tabindex="11"
                         [ngClass]="sunleonForm.get('pvReadyZone1').invalid && (sunleonForm.get('pvReadyZone1').dirty || sunleonForm.get('pvReadyZone1').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('pvReadyZone1').errors?.['required']">Cocher PV Ready</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="inclinaisonZone1" class="form-label">Inclinaison <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="inclinaisonZone1" formControlName="inclinaisonZone1" placeholder="Ex : 100" tabindex="12"
                             [ngClass]="sunleonForm.get('inclinaisonZone1').invalid && (sunleonForm.get('inclinaisonZone1').dirty || sunleonForm.get('inclinaisonZone1').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('inclinaisonZone1').errors?.['pattern']">L'inclinaison n'est pas valide</div>
                        <div *ngIf="sunleonForm.get('inclinaisonZone1').errors?.['required']">L'inclinaison est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">°</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div *ngIf="sunleonForm.get('typePoseZone1').value === 'OMBRIERE'">
            <h2>Ombrière</h2>
            <div class="row">
              <div class="col-4">
                <label class="form-label" for="typeVehiculeParkingZone1">Type véhicule sur le parking <span class="required">*</span></label>
                <select class="form-select" id="typeVehiculeParkingZone1" formControlName="typeVehiculeParkingZone1" tabindex="13"
                        [ngClass]="sunleonForm.get('typeVehiculeParkingZone1').invalid && (sunleonForm.get('typeVehiculeParkingZone1').dirty || sunleonForm.get('typeVehiculeParkingZone1').touched) ? 'is-invalid' : ''">
                  <option value=""></option>
                  <option *ngFor="let typeVehiculeParkingZone1 of typeParkings" [ngValue]="typeVehiculeParkingZone1.code"
                          [selected]="sunleonForm.get('typeVehiculeParkingZone1').value && typeVehiculeParkingZone1.code === sunleonForm.get('typeVehiculeParkingZone1').value">
                    {{ typeVehiculeParkingZone1.label }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <div *ngIf="sunleonForm.get('typeVehiculeParkingZone1').errors?.['required']">Le type véhicule sur le parking est obligatoire</div>
                </div>
              </div>
              <div class="col-4">
                <div *ngIf="sunleonForm.get('typeVehiculeParkingZone1').value === 'VL'">
                  <label class="form-label" for="typePlaceParkingZone1">Type de place sur le parking <span class="required">*</span></label>
                  <select class="form-select" id="typePlaceParkingZone1" formControlName="typePlaceParkingZone1" tabindex="14"
                          [ngClass]="sunleonForm.get('typePlaceParkingZone1').invalid && (sunleonForm.get('typePlaceParkingZone1').dirty || sunleonForm.get('typePlaceParkingZone1').touched) ? 'is-invalid' : ''">
                    <option value=""></option>
                    <option *ngFor="let typePlaceParkingZone1 of typePlaces" [ngValue]="typePlaceParkingZone1.code"
                            [selected]="sunleonForm.get('typePlaceParkingZone1').value && typePlaceParkingZone1.code === sunleonForm.get('typePlaceParkingZone1').value">
                      {{ typePlaceParkingZone1.label }}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('typePlaceParkingZone1').errors?.['required']">Le type de place sur le parking est obligatoire</div>
                  </div>
                </div>
              </div>
              <div class="col-4">
              </div>
            </div>
          </div>
          </div>
          <div class=row>
            <div class="col-12">
              &nbsp;
            </div>
          </div>
          <div class="sunleon-zone">
            <h2>Zone n°2</h2>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label" for="typePoseZone2">Type de pose</label>
                  <select class="form-select" id="typePoseZone2" formControlName="typePoseZone2" tabindex="15"
                          [ngClass]="sunleonForm.get('typePoseZone2').invalid && (sunleonForm.get('typePoseZone2').dirty || sunleonForm.get('typePoseZone2').touched) ? 'is-invalid' : ''">
                    <option value=""></option>
                    <option *ngFor="let typePose of typePoses" [ngValue]="typePose.code"
                            [selected]="sunleonForm.get('typePoseZone2').value && typePose.code === sunleonForm.get('typePoseZone2').value">
                      {{ typePose.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4" *ngIf="sunleonForm.get('typePoseZone2').value">
                <div class="mb-2">
                  <div class="row">
                    <label for="surfaceZone2" class="form-label">Surface <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="surfaceZone2" formControlName="surfaceZone2" placeholder="Ex : 45.32" tabindex="16"
                             [ngClass]="sunleonForm.get('surfaceZone2').invalid && (sunleonForm.get('surfaceZone2').dirty || sunleonForm.get('surfaceZone2').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('surfaceZone2').errors?.['required']">La surface est obligatoire</div>
                        <div *ngIf="sunleonForm.get('surfaceZone2').errors?.['pattern']">La surface n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">m²</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4" *ngIf="sunleonForm.get('typePoseZone2').value">
                <div class="mb-2">
                  <div class="row">
                    <label for="azimutZone2" class="form-label">Azimut (compris entre -180 et 180, SUD = 0) <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="azimutZone2" formControlName="azimutZone2" placeholder="Ex : 100" tabindex="17"
                             [ngClass]="sunleonForm.get('azimutZone2').invalid && (sunleonForm.get('azimutZone2').dirty || sunleonForm.get('azimutZone2').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('azimutZone2').errors?.['required']">L'azimut est obligatoire</div>
                        <div *ngIf="sunleonForm.get('azimutZone2').errors?.['pattern']">L'azimut n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">°</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="sunleonForm.get('typePoseZone2').value">
              <div *ngIf="sunleonForm.get('typePoseZone2').value === 'TOITURE'">
                <h2>Toiture</h2>
                <div class="row">
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label" for="typeToitureZone2">Type toiture <span class="required">*</span></label>
                      <select class="form-select" id="typeToitureZone2" formControlName="typeToitureZone2" tabindex="18"
                              [ngClass]="sunleonForm.get('typeToitureZone2').invalid && (sunleonForm.get('typeToitureZone2').dirty || sunleonForm.get('typeToitureZone2').touched) ? 'is-invalid' : ''">>
                        <option value=""></option>
                        <option *ngFor="let typeToiture of typeToitures" [ngValue]="typeToiture.code"
                                [selected]="sunleonForm.get('typeToitureZone2').value && typeToiture.code === sunleonForm.get('typeToitureZone2').value">
                          {{ typeToiture.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typeToitureZone2').errors?.['required']">Le type de toiture est obligatoire</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group form-check">
                      <span class="spanCheckbox">PV Ready <span class="required">*</span></span>
                      <input class="form-check-input" type="checkbox" formControlName="pvReadyZone2" tabindex="19"
                             [ngClass]="sunleonForm.get('pvReadyZone2').invalid && (sunleonForm.get('pvReadyZone2').dirty || sunleonForm.get('pvReadyZone2').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('pvReadyZone2').errors?.['required']">Cocher PV Ready</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-2">
                      <div class="row">
                        <label for="inclinaisonZone2" class="form-label">Inclinaison <span class="required">*</span></label>
                        <div class="col-10">
                          <input type="number" class="form-control" id="inclinaisonZone2" formControlName="inclinaisonZone2" placeholder="Ex : 100" tabindex="20"
                                 [ngClass]="sunleonForm.get('inclinaisonZone2').invalid && (sunleonForm.get('inclinaisonZone2').dirty || sunleonForm.get('inclinaisonZone2').touched) ? 'is-invalid' : ''">
                          <div class="invalid-feedback">
                            <div *ngIf="sunleonForm.get('inclinaisonZone2').errors?.['pattern']">L'inclinaison n'est pas valide</div>
                            <div *ngIf="sunleonForm.get('inclinaisonZone2').errors?.['required']">L'inclinaison est obligatoire</div>
                          </div>
                        </div>
                        <div class="col-1">
                          <span class="spanTextMiddle">°</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="sunleonForm.get('typePoseZone2').value === 'OMBRIERE'">
                <h2>Ombrière</h2>
                <div class="row">
                  <div class="col-4">
                    <label class="form-label" for="typeVehiculeParkingZone2">Type véhicule sur parking <span class="required">*</span></label>
                    <select class="form-select" id="typeVehiculeParkingZone2" formControlName="typeVehiculeParkingZone2" tabindex="21"
                            [ngClass]="sunleonForm.get('typeVehiculeParkingZone2').invalid && (sunleonForm.get('typeVehiculeParkingZone2').dirty || sunleonForm.get('typeVehiculeParkingZone2').touched) ? 'is-invalid' : ''">
                      <option value=""></option>
                      <option *ngFor="let typeParking of typeParkings" [ngValue]="typeParking.code"
                              [selected]="sunleonForm.get('typeVehiculeParkingZone2').value && typeParking.code === sunleonForm.get('typeVehiculeParkingZone2').value">
                        {{ typeParking.label }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      <div *ngIf="sunleonForm.get('typeVehiculeParkingZone2').errors?.['required']">Le type de véhicule sur le parking est obligatoire</div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div *ngIf="sunleonForm.get('typeVehiculeParkingZone2').value === 'VL'">
                      <label class="form-label" for="typePlaceParkingZone2">Type de place sur le parking <span class="required">*</span></label>
                      <select class="form-select" id="typePlaceParkingZone2" formControlName="typePlaceParkingZone2" tabindex="22"
                              [ngClass]="sunleonForm.get('typePlaceParkingZone2').invalid && (sunleonForm.get('typePlaceParkingZone2').dirty || sunleonForm.get('typePlaceParkingZone2').touched) ? 'is-invalid' : ''">
                        <option value=""></option>
                        <option *ngFor="let typePlace of typePlaces" [ngValue]="typePlace.code"
                                [selected]="sunleonForm.get('typePlaceParkingZone2').value && typePlace.code === sunleonForm.get('typePlaceParkingZone2').value">
                          {{ typePlace.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typePlaceParkingZone2').errors?.['required']">Le type de place sur le parking est obligatoire</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                  </div>
                </div>
                </div>
              </div>
          </div>
          <div class=row>
            <div class="col-12">
              &nbsp;
            </div>
          </div>
          <div class="sunleon-zone">
            <h2>Zone n°3</h2>
            <div class="row">
              <div class="col-4">
                <div class="mb-3">
                  <label class="form-label" for="typePoseZone3">Type de pose</label>
                  <select class="form-select" id="typePoseZone3" formControlName="typePoseZone3" tabindex="23"
                          [ngClass]="sunleonForm.get('typePoseZone3').invalid && (sunleonForm.get('typePoseZone3').dirty || sunleonForm.get('typePoseZone3').touched) ? 'is-invalid' : ''">
                    <option value=""></option>
                    <option *ngFor="let typePose of typePoses" [ngValue]="typePose.code"
                            [selected]="sunleonForm.get('typePoseZone3').value && typePose.code === sunleonForm.get('typePoseZone3').value">
                      {{ typePose.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-4" *ngIf="sunleonForm.get('typePoseZone3').value">
                <div class="mb-2">
                  <div class="row">
                    <label for="surfaceZone3" class="form-label">Surface <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="surfaceZone3" formControlName="surfaceZone3" placeholder="Ex : 45.32" tabindex="24"
                             [ngClass]="sunleonForm.get('surfaceZone3').invalid && (sunleonForm.get('surfaceZone3').dirty || sunleonForm.get('surfaceZone3').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('surfaceZone3').errors?.['required']">La surface est obligatoire</div>
                        <div *ngIf="sunleonForm.get('surfaceZone3').errors?.['pattern']">La surface n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">m²</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4" *ngIf="sunleonForm.get('typePoseZone3').value">
                <div class="mb-2">
                  <div class="row">
                    <label for="azimutZone3" class="form-label">Azimut (compris entre -180 et 180, SUD = 0) <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="azimutZone3" formControlName="azimutZone3" placeholder="Ex : 100" tabindex="25"
                             [ngClass]="sunleonForm.get('azimutZone3').invalid && (sunleonForm.get('azimutZone3').dirty || sunleonForm.get('azimutZone3').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('azimutZone3').errors?.['required']">L'azimut est obligatoire</div>
                        <div *ngIf="sunleonForm.get('azimutZone3').errors?.['pattern']">L'azimut n'est pas valide</div>
                      </div>
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">°</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="sunleonForm.get('typePoseZone3').value">
              <div *ngIf="sunleonForm.get('typePoseZone3').value === 'TOITURE'">
                <h2>Toiture</h2>
                <div class="row">
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label" for="typeToitureZone3">Type toiture <span class="required">*</span></label>
                      <select class="form-select" id="typeToitureZone3" formControlName="typeToitureZone3" tabindex="26"
                              [ngClass]="sunleonForm.get('typeToitureZone3').invalid && (sunleonForm.get('typeToitureZone3').dirty || sunleonForm.get('typeToitureZone3').touched) ? 'is-invalid' : ''">
                        <option value=""></option>
                        <option *ngFor="let typeToiture of typeToitures" [ngValue]="typeToiture.code"
                                [selected]="sunleonForm.get('typeToitureZone3').value && typeToiture.code === sunleonForm.get('typeToitureZone3').value">
                          {{ typeToiture.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typeToitureZone3').errors?.['required']">Le type de toiture est obligatoire</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group form-check">
                      <span class="spanCheckbox">PV Ready <span class="required">*</span></span>
                      <input class="form-check-input" type="checkbox" formControlName="pvReadyZone3" tabindex="27"
                          [ngClass]="sunleonForm.get('pvReadyZone3').invalid && (sunleonForm.get('pvReadyZone3').dirty || sunleonForm.get('pvReadyZone3').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('pvReadyZone3').errors?.['required']">Cocher PV Ready</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-2">
                      <div class="row">
                        <label for="inclinaisonZone3" class="form-label">Inclinaison <span class="required">*</span></label>
                        <div class="col-10">
                          <input type="number" class="form-control" id="inclinaisonZone3" formControlName="inclinaisonZone3" placeholder="Ex : 100" tabindex="28"
                                 [ngClass]="sunleonForm.get('inclinaisonZone3').invalid && (sunleonForm.get('inclinaisonZone3').dirty || sunleonForm.get('inclinaisonZone3').touched) ? 'is-invalid' : ''">
                          <div class="invalid-feedback">
                            <div *ngIf="sunleonForm.get('inclinaisonZone3').errors?.['pattern']">L'inclinaison n'est pas valide</div>
                            <div *ngIf="sunleonForm.get('inclinaisonZone3').errors?.['required']">L'inclinaison est obligatoire</div>
                          </div>
                        </div>
                        <div class="col-1">
                          <span class="spanTextMiddle">°</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="sunleonForm.get('typePoseZone3').value === 'OMBRIERE'">
                <h2>Ombrière</h2>
                <div class="row">
                  <div class="col-4">
                    <label class="form-label" for="typeVehiculeParkingZone3">Type véhicule sur parking <span class="required">*</span></label>
                    <select class="form-select" id="typeVehiculeParkingZone3" formControlName="typeVehiculeParkingZone3" tabindex="29"
                            [ngClass]="sunleonForm.get('typeVehiculeParkingZone3').invalid && (sunleonForm.get('typeVehiculeParkingZone3').dirty || sunleonForm.get('typeVehiculeParkingZone3').touched) ? 'is-invalid' : ''">
                      <option value=""></option>
                      <option *ngFor="let typeParking of typeParkings" [ngValue]="typeParking.code"
                              [selected]="sunleonForm.get('typeVehiculeParkingZone3').value && typeParking.code === sunleonForm.get('typeVehiculeParkingZone3').value">
                        {{ typeParking.label }}
                      </option>
                    </select>
                    <div class="invalid-feedback">
                      <div *ngIf="sunleonForm.get('typeVehiculeParkingZone3').errors?.['required']">Le type de véhicule sur le parking est obligatoire</div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div *ngIf="sunleonForm.get('typeVehiculeParkingZone3').value === 'VL'">
                      <label class="form-label" for="typePlaceParkingZone3">Type de place sur le parking <span class="required">*</span></label>
                      <select class="form-select" id="typePlaceParkingZone3" formControlName="typePlaceParkingZone3" tabindex="30"
                              [ngClass]="sunleonForm.get('typePlaceParkingZone3').invalid && (sunleonForm.get('typePlaceParkingZone3').dirty || sunleonForm.get('typePlaceParkingZone3').touched) ? 'is-invalid' : ''">
                        <option value=""></option>
                        <option *ngFor="let typePlace of typePlaces" [ngValue]="typePlace.code"
                                [selected]="sunleonForm.get('typePlaceParkingZone3').value && typePlace.code === sunleonForm.get('typePlaceParkingZone3').value">
                          {{ typePlace.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typePlaceParkingZone3').errors?.['required']">Le type de place sur le parking est obligatoire</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                  </div>
                </div>
                </div>
              </div>
          </div>
          <div class="mb-2">
            <div class="row">
              <h2>Durée du contrat <span class="required">*</span></h2>
              <div class="col-2">
                <div class="mb-3">
                  <div class="btn-group d-block" role="group">
                    <ng-container *ngFor="let dureeContrat of dureeContrats">
                      <input type="radio" class="btn-check" name="optionsDureeContrat" [value]="dureeContrat.label" id="{{ dureeContrat.code}}"
                             (click)='onRadioClick(dureeContrat.label, "dureeContrat")' tabindex="31" >
                      <label class="btn btn-outline-success" for="{{ dureeContrat.code }}">{{ dureeContrat.label }}</label>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-10">
                <span class="spanTextMiddle">ans</span>
              </div>
              <input type="hidden" formControlName="dureeContrat" name="dureeContrat"
                     [ngClass]="sunleonForm.get('dureeContrat').invalid && (sunleonForm.get('dureeContrat').dirty || sunleonForm.get('dureeContrat').touched) ? 'is-invalid' : ''">
              <div class="invalid-feedback">
                <div *ngIf="sunleonForm.get('dureeContrat').errors?.['required']">La durée du contrat est obligatoire</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="form-group form-check">
                <span class="spanCheckbox">Travaux projet neuf</span>
                <input class="form-check-input" type="checkbox" formControlName="encadrementProjetNeuf" tabindex="32">
              </div>
            </div>
          </div>
          <h2>Mode valorisation <span class="required">*</span></h2>
          <div class="mb-3">
            <div class="btn-group d-block" role="group">
              <ng-container *ngFor="let modeValorisation of modeValorisations">
                <input type="radio" class="btn-check" name="options" [value]="modeValorisation.code" id="{{ modeValorisation.code }}"
                       (click)='onRadioClick(modeValorisation.code, "modeValorisation")' tabindex="33">
                <label class="btn btn-outline-success" for="{{ modeValorisation.code }}"> {{ modeValorisation.code }} - {{ modeValorisation.label }}</label>
              </ng-container>
            </div>
            <input type="hidden" formControlName="modeValorisation" name="modeValorisation"
                   [ngClass]="sunleonForm.get('modeValorisation').invalid && (sunleonForm.get('modeValorisation').dirty || sunleonForm.get('modeValorisation').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="sunleonForm.get('modeValorisation').errors?.['required']">Le mode valorisation est obligatoire</div>
            </div>
          </div>

          <div *ngIf="sunleonForm.get('modeValorisation').value === 'VT'" >
            <div class="mb-2">
              <div class="row">
                <label for="distanceRaccordementPublic" class="form-label">Distance raccordement public <span class="required">*</span></label>
                <div class="col-11">
                  <input type="number" class="form-control" id="distanceRaccordementPublic" formControlName="distanceRaccordementPublic" placeholder="Ex : 400 m" tabindex="34"
                         [ngClass]="sunleonForm.get('distanceRaccordementPublic').invalid && (sunleonForm.get('distanceRaccordementPublic').dirty
                          || sunleonForm.get('distanceRaccordementPublic').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('distanceRaccordementPublic').errors?.['required']">La distance raccordement public est obligatoire</div>
                  </div>
                </div>
                <div class="col-1">
                  <span class="spanTextMiddle">m</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="sunleonForm.get('modeValorisation').value === 'AC'" >
            <div class="mb-2">
              <div class="row">
                <label for="puissanceSouscriteKVA" class="form-label">Puissance souscrite  <span class="required">*</span></label>
                <div class="col-11">
                  <input type="number" class="form-control" id="puissanceSouscriteKVA" formControlName="puissanceSouscriteKVA" placeholder="Ex : 343 kVA" tabindex="35"
                         [ngClass]="sunleonForm.get('puissanceSouscriteKVA').invalid && (sunleonForm.get('puissanceSouscriteKVA').dirty
                          || sunleonForm.get('puissanceSouscriteKVA').touched) ? 'is-invalid' : ''">
                  <div class="invalid-feedback">
                    <div *ngIf="sunleonForm.get('puissanceSouscriteKVA').errors?.['pattern']">La puissance souscrite n'est pas valide</div>
                    <div *ngIf="sunleonForm.get('puissanceSouscriteKVA').errors?.['required']">La puissance souscrite est obligatoire</div>
                  </div>
                </div>
                <div class="col-1">
                  <span class="spanTextMiddle">kVA</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="travauxDistanceVoirieVrd" class="form-label">Distance voirie VRD</label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="travauxDistanceVoirieVrd" formControlName="travauxDistanceVoirieVrd" placeholder="Ex : 500" tabindex="36">
                    </div>
                    <div class="col-1">
                      <span class="spanTextMiddle">m</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="form-group form-check">
                  <span class="spanCheckbox">Demande client ajouter dispositif non injection ?</span>
                  <input class="form-check-input" type="checkbox" formControlName="dispositifNonInjection" tabindex="37">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group form-check">
                  <span class="spanCheckbox">Raccordement HTA (Ajout d’un PTR au PDL du client)</span>
                  <input class="form-check-input" type="checkbox" formControlName="ptrAC" tabindex="38">
                </div>
            </div>
            </div>
            <div class="row">
              <h2>Informations site</h2>
              <div class="col-4">
                <div class="form-group form-check">
                  <span class="spanCheckbox">Disponibilité TGBT client ?</span>
                  <input class="form-check-input" type="checkbox" formControlName="dispoTGBT" tabindex="39">
                </div>
              </div>
              <div class="col-4">
                <div class="form-group form-check">
                  <span class="spanCheckbox">Automate déjà présent client ?</span>
                  <input class="form-check-input" type="checkbox" formControlName="automate" tabindex="40">
                </div>
              </div>
            </div>
            <h2>Bilan energétique</h2>
            <div class="mb-2">
              <div class="row">
                <div class="col-4">
                  <div class="mb-2">
                    <label for="prm" class="form-label">Courbe de charge (PRM) <span class="required">*</span></label>
                    <ng-select id="prm" formControlName="prm" [clearable]="false" tabindex="41"
                               [ngClass]="sunleonForm.get('prm').invalid && (sunleonForm.get('prm').dirty || sunleonForm.get('prm').touched) ? 'is-invalid' : ''">
                      <ng-option *ngFor="let prmLigne of prmCodes" [value]="prmLigne.code">{{prmLigne.code}}</ng-option>
                    </ng-select>
                    <div class="invalid-feedback">
                      <div *ngIf="sunleonForm.get('prm').errors?.['required']">Le prm est obligatoire</div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="mb-2">
                    <label for="fichier-button" class="form-label"></label>
                  </div>
                  <div class="mb-2">
                    <button type="button" id="fichier-button" (click)="openModal()" class="btn btn-submit btn-primary"  [disabled]="formIsSubmitting">
                      Charger un nouveau fichier
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h2>Tarifs de l'énergie</h2>
            <div class="row">
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="tarifHch" class="form-label">Heure Creuse Haute (HCH)  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="tarifHch" formControlName="tarifHch" placeholder="Ex : 3.07" tabindex="42"
                             [ngClass]="sunleonForm.get('tarifHch').invalid && (sunleonForm.get('tarifHch').dirty
                          || sunleonForm.get('tarifHch').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('tarifHch').errors?.['required']">Heure Creuse Haute est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">c€/kWh</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="tarifHph" class="form-label">Heure Pleine Haute (HPH)  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="tarifHph" formControlName="tarifHph" placeholder="Ex : 3.07" tabindex="43"
                             [ngClass]="sunleonForm.get('tarifHph').invalid && (sunleonForm.get('tarifHph').dirty
                          || sunleonForm.get('tarifHph').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('tarifHph').errors?.['required']">Heure Pleine Haute est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">c€/kWh</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="tarifHpt" class="form-label">Heure Pointe (HPT)  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="tarifHpt" formControlName="tarifHpt" placeholder="Ex : 3.07" tabindex="44"
                             [ngClass]="sunleonForm.get('tarifHpt').invalid && (sunleonForm.get('tarifHpt').dirty
                          || sunleonForm.get('tarifHpt').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('tarifHpt').errors?.['required']">Heure Pointe est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">c€/kWh</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="tarifHcb" class="form-label">Heure Creuse Basse (HCB)  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="tarifHcb" formControlName="tarifHcb" placeholder="Ex : 3.07" tabindex="45"
                             [ngClass]="sunleonForm.get('tarifHcb').invalid && (sunleonForm.get('tarifHcb').dirty
                          || sunleonForm.get('tarifHcb').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('tarifHcb').errors?.['required']">Heure Creuse Basse est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">c€/kWh</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="mb-2">
                  <div class="row">
                    <label for="tarifHpb" class="form-label">Heure Pleine Basse (HPB)  <span class="required">*</span></label>
                    <div class="col-10">
                      <input type="number" class="form-control" id="tarifHpb" formControlName="tarifHpb" placeholder="Ex : 3.07" tabindex="46"
                             [ngClass]="sunleonForm.get('tarifHpb').invalid && (sunleonForm.get('tarifHpb').dirty
                          || sunleonForm.get('tarifHpb').touched) ? 'is-invalid' : ''">
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('tarifHpb').errors?.['required']">Heure Pleine Basse est obligatoire</div>
                      </div>
                    </div>
                    <div class="col-2">
                      <span class="spanTextMiddle">c€/kWh</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ngb-accordion [closeOthers]="true" class="accordion-flush">
            <ngb-panel title="CAPEX généralisés" class="custom-panel">
              <ng-template ngbPanelContent>
                <h2>Choix matériels</h2>
                <div class="row">
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label" for="typeGammePanneau">Gamme panneau <span class="required">*</span></label>
                      <select class="form-select" id="typeGammePanneau" formControlName="typeGammePanneau" tabindex="47"
                              [ngClass]="sunleonForm.get('typeGammePanneau').invalid && (sunleonForm.get('typeGammePanneau').dirty || sunleonForm.get('typeGammePanneau').touched) ? 'is-invalid' : ''">
                        <option *ngFor="let typeGammePanneau of typeGammePanneaux" [ngValue]="typeGammePanneau.code"
                                [selected]="sunleonForm.get('typeGammePanneau').value && typeGammePanneau.code === sunleonForm.get('typeGammePanneau').value">
                          {{ typeGammePanneau.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typeGammePanneau').errors?.['required']">Le type de gamme panneau est obligatoire</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="mb-3">
                      <label class="form-label" for="typeGammeOnduleur">Gamme onduleur <span class="required">*</span></label>
                      <select class="form-select" id="typeGammeOnduleur" formControlName="typeGammeOnduleur" tabindex="48"
                              [ngClass]="sunleonForm.get('typeGammeOnduleur').invalid && (sunleonForm.get('typeGammeOnduleur').dirty || sunleonForm.get('typeGammeOnduleur').touched) ? 'is-invalid' : ''">
                        <option *ngFor="let typeGammeOnduleur of typeGammeOnduleurs" [ngValue]="typeGammeOnduleur.code"
                                [selected]="sunleonForm.get('typeGammeOnduleur').value && typeGammeOnduleur.code === sunleonForm.get('typeGammeOnduleur').value">
                          {{ typeGammeOnduleur.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <div *ngIf="sunleonForm.get('typeGammeOnduleur').errors?.['required']">Le type de gamme onduleur est obligatoire</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group form-check" *ngIf="sunleonForm.get('typeGammeOnduleur').value === 'STRING'">
                      <span class="spanCheckbox">ICPE ? (ajoute coffret DC)</span>
                      <input class="form-check-input" type="checkbox" formControlName="coffretDc" tabindex="49">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group form-check" *ngIf="sunleonForm.get('typeGammeOnduleur').value === 'STRING'">
                      <span class="spanCheckbox">Paratonnerre présent sur site ?</span>
                      <input class="form-check-input" type="checkbox" formControlName="paratonnerre" tabindex="50">
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group form-check">
                      <span class="spanCheckbox">Proche aérodrome ? (ajoute étude éblouissement)</span>
                      <input class="form-check-input" type="checkbox" formControlName="aerodrome" tabindex="51">
                    </div>
                  </div>
                </div>
                <h2>Autre</h2>
                <div class="row">
                  <div class="col-4">
                    <div class="mb-2">
                      <div class="row">
                        <label for="prixApportAffaireHtWc" class="form-label">Prix apport affaire</label>
                        <div class="col-10">
                          <input type="number" class="form-control" id="prixApportAffaireHtWc" formControlName="prixApportAffaireHtWc" placeholder="Ex : 0" tabindex="52">
                        </div>
                        <div class="col-1">
                          <span class="spanTextMiddle">€/Wc</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <h2>Travaux spécifiques</h2>
                <div class="col-4">
                  <div class="mb-2">
                    <div class="row">
                      <label for="travauxDiversHT" class="form-label">Travaux Divers</label>
                      <div class="col-10">
                        <input type="number" class="form-control" id="travauxDiversHT" formControlName="travauxDiversHT" placeholder="Ex : 10000" tabindex="53">
                      </div>
                      <div class="col-1">
                        <span class="spanTextMiddle">€</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group form-check">
                          <span class="spanCheckbox">Station Météo</span>
                          <input class="form-check-input" type="checkbox" formControlName="stationMeteo" tabindex="54">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              </ngb-panel>
              <ngb-panel title="CAPEX par type de pose" class="custom-panel">
                  <ng-template ngbPanelContent>
                    <div *ngIf="sunleonForm.get('typePoseZone1').value === 'OMBRIERE' ||
                                sunleonForm.get('typePoseZone2').value === 'OMBRIERE' ||
                                sunleonForm.get('typePoseZone3').value === 'OMBRIERE'">
                      <div class="row">
                        <h2>Ombrière</h2>
                        <div class="col-4">
                          <label for="deposeCandelabre" class="form-label">Nombre candélabres à retirer</label>
                          <input type="number" class="form-control" id="deposeCandelabre" formControlName="deposeCandelabre" placeholder="Ex : 2" tabindex="55">
                        </div>
                        <div class="col-4">
                          <label for="poseCandelabre" class="form-label">Nombre candélabres à poser et raccorder</label>
                          <input type="number" class="form-control" id="poseCandelabre" formControlName="poseCandelabre" placeholder="Ex : 3" tabindex="56">
                        </div>
                        <div class="col-4">
                          <label for="nbAbattageArbres" class="form-label">Nombre d'arbres à abattre</label>
                            <input type="number" class="form-control" id="nbAbattageArbres" formControlName="nbAbattageArbres" placeholder="Ex : 4" tabindex="57">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4">
                          <div class="form-group form-check">
                            <span class="spanCheckbox">Eclairage sous ombrière ?</span>
                            <input class="form-check-input" type="checkbox" formControlName="eclairageSousOmbriere" tabindex="58">
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="form-group form-check">
                            <span class="spanCheckbox">Descente EP ALU</span>
                            <input class="form-check-input" type="checkbox" formControlName="descenteEpAlu" tabindex="58">
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="row" *ngIf="sunleonForm.get('typePoseZone1').value === 'SOL' ||
                                            sunleonForm.get('typePoseZone2').value === 'SOL' ||
                                            sunleonForm.get('typePoseZone3').value === 'SOL'">
                      <h2>Sol</h2>
                      <div class="col-4">
                        <div class="mb-2">
                          <div class="row">
                            <label for="mlClotureSol" class="form-label">Clôture sol</label>
                            <div class="col-10">
                              <input type="number" class="form-control" id="mlClotureSol" formControlName="mlClotureSol" placeholder="Ex : 0" tabindex="59">
                            </div>
                            <div class="col-1">
                              <span class="spanTextMiddle">mL</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <label for="nbPortailsSol" class="form-label">Nombre portails</label>
                        <input type="number" class="form-control" id="nbPortailsSol" formControlName="nbPortailsSol" placeholder="Ex : 3" tabindex="60">
                      </div>
                    </div>
                    <div class="row" *ngIf="sunleonForm.get('typePoseZone1').value === 'TOITURE' ||
                                            sunleonForm.get('typePoseZone2').value === 'TOITURE' ||
                                            sunleonForm.get('typePoseZone3').value === 'TOITURE'">
                      <h2>Toiture</h2>
                      <div class="col-4">
                          <div class="row">
                            <label for="mlGardeCorpsProvisoire" class="form-label">Garde corps provisoire</label>
                            <div class="col-10">
                              <input type="number" class="form-control" id="mlGardeCorpsProvisoire" formControlName="mlGardeCorpsProvisoire" placeholder="Ex : 0" tabindex="61">
                            </div>
                            <div class="col-1">
                              <span class="spanTextMiddle">mL</span>
                            </div>
                          </div>        
                      </div>
                      <div class="col-4">             
                        <div class="row">
                          <label for="mlLigneDeVie" class="form-label">Ligne de vie</label>
                          <div class="col-10">
                            <input type="number" class="form-control" id="mlLigneDeVie" formControlName="mlLigneDeVie" placeholder="Ex : 0" tabindex="62">
                          </div>
                          <div class="col-1">
                            <span class="spanTextMiddle">mL</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <label for="nbEchelleCrinoline" class="form-label">Echelle à crinoline</label>
                          <div class="col-12">
                            <input type="number" class="form-control" id="nbEchelleCrinoline" formControlName="nbEchelleCrinoline" placeholder="Ex : 0" tabindex="63">
                          </div>
                        </div>
                      </div>
                    </div>
                </ng-template>
              </ngb-panel>
          </ngb-accordion>

          <div class="row" *ngIf="user.administrateur">
            <h2>Mode administrateur</h2>

            <div class="col-2">
              <div class="form-group form-check" >
                <span class="spanCheckbox">Csv détaillé</span>
                <input class="form-check-input" type="checkbox" formControlName="modeDebug" tabindex="64">
              </div>
            </div>
            <div class="col-10" *ngIf="sunleonForm.get('modeValorisation').value === 'VT'" >
              <div class="form-group form-check" >
                <span class="spanCheckbox">VT - forcer soulte et rente initiales</span>
                <input class="form-check-input" type="checkbox" formControlName="forcerSoulteEtRenteInialeVT" tabindex="65">
              </div>
            </div>

          </div>
          <div class="row">
            <div class="bouton">
              <button id='spinner-button'
                      type='submit'
                      class='btn btn-submit btn-primary'
                      [disabled]='formIsSubmitting'
                      [disabled]='modalService.hasOpenModals()'
                      tabindex='66'>
                Lancer la simulation
                <div *ngIf='formIsSubmitting' class='col-3 text-center spinner-border text-success' role='status'>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Intégration du fichier courbe de charge Enedis</h5>
    <button type="button" class="btn-close btn-primary" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body">
    <input type="file" (change)="onFileSelected($event)" accept=".csv">
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
      <div class="row">
        <div class="col-7">
          <button type="button" class="btn btn-modal btn-secondary" (click)="modal.close('Close click')">Fermer</button>
        </div>
        <div class="col-5">
          <button type="submit" class="btn btn-modal btn-primary" (click)="onSubmit() ">Valider
            <div *ngIf="formModalIsSubmitting" class="col-3 text-center spinner-border text-success" role="status"></div>
          </button>
        </div>
      </div>
  </div>
</ng-template>

<ng-template #contentSuccess let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Simulation terminée</h5>
    <button type="button" class="btn-close btn-primary" aria-label="Close" (click)="modal.dismiss('Close click')">
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ apiMessageSuccess }}</h5>
  </div>
  <div class="modal-footer d-flex flex-column align-items-stretch">
    <div class="row">
      <div class="col-7">
      </div>
      <div class="col-5">
        <button type="button" class="btn btn-modal btn-primary" (click)="modal.close('Close click')">Fermer</button>
      </div>
    </div>
  </div>
</ng-template>
