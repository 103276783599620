<div [formGroup]="capexTypePoseForm">
  <div class='container'>
    <div class="card" *ngIf='_shouldDisplaySectionOmbriere'>
      <div class="card-body">
        <h2 class="card-title">Ombrière</h2>
        <div class="row">
          <div class="col-12">
            <label for="deposeCandelabre" class="form-label">Nombre candélabres à retirer</label>
            <input type="number" class="form-control" id="deposeCandelabre" formControlName="deposeCandelabre" placeholder="Ex : 2" tabindex="52">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="poseCandelabre" class="form-label">Nombre candélabres à poser et raccorder</label>
            <input type="number" class="form-control" id="poseCandelabre" formControlName="poseCandelabre" placeholder="Ex : 3" tabindex="53">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="nbAbattageArbres" class="form-label">Nombre d'arbres à abattre</label>
            <input type="number" class="form-control" id="nbAbattageArbres" formControlName="nbAbattageArbres" placeholder="Ex : 4" tabindex="54">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group form-check">
              <span class="spanCheckbox">Eclairage sous ombrière ?</span>
              <input class="form-check-input" type="checkbox" formControlName="eclairageSousOmbriere" tabindex="55">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group form-check">
              <span class="spanCheckbox">Descente EP ALU</span>
              <input class="form-check-input" type="checkbox" formControlName="descenteEpAlu" tabindex="56">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf='_shouldDisplaySectionSol'>
      <div class="card-body">
        <h2 class="card-title">Sol</h2>
        <div class="row" >
          <div class="col-6">
              <div class="row">
                <label for="mlClotureSol" class="form-label">Clôture sol</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="mlClotureSol" formControlName="mlClotureSol" placeholder="Ex : 0" tabindex="57">
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">mL</span>
                </div>
            </div>
          </div>
          <div class="col-6">
            <label for="nbPortailsSol" class="form-label">Nombre portails</label>
            <input type="number" class="form-control" id="nbPortailsSol" formControlName="nbPortailsSol" placeholder="Ex : 3" tabindex="58">
          </div>
        </div>
      </div>
    </div>

    <div class="card" *ngIf='_shouldDisplaySectionToiture'>
      <div class="card-body">
        <h2 class="card-title">Toiture</h2>
        <div class="row" >
          <div class="col-12">
              <div class="row">
                <label for="mlGardeCorpsProvisoire" class="form-label">Garde corps provisoire</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="mlGardeCorpsProvisoire" formControlName="mlGardeCorpsProvisoire" placeholder="Ex : 0" tabindex="59">
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">mL</span>
                </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-12">
              <div class="row">
                <label for="mlLigneDeVie" class="form-label">Ligne de vie</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="mlLigneDeVie" formControlName="mlLigneDeVie" placeholder="Ex : 0" tabindex="60">
                </div>
                <div class="col-2">
                  <span class="spanTextMiddle">mL</span>
                </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-12">
              <div class="row">
                <label for="nbEchelleCrinoline" class="form-label">Echelle à crinoline</label>
                <div class="col-10">
                  <input type="number" class="form-control" id="nbEchelleCrinoline" formControlName="nbEchelleCrinoline" placeholder="Ex : 0" tabindex="61">   
                </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

